<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        v-bind="$attrs"
        :color="method.color"
        small
        icon
        v-on="on"
      >
        <i
          :class="method.icon"
          class="fa-xl"
        />
      </v-btn>
    </template>
    {{ method.text }}
  </v-tooltip>
</template>
<script>
export default {
  props: {
    route: {
      type: String,
      default: null,
    },
  },
  computed: {
    method () {
      return this.$delivery_methods.find(x => x.value === this.route) || {}
    },
  },
}
</script>
